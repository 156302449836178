@font-face {
  font-family: "Sun Life Sans";
  src: url("./assets/fonts/SunLifeSans-Regular/SunLifeSans-Regular.eot")
      format("embedded-opentype"),
    url("./assets/fonts/SunLifeSans-Regular/SunLifeSans-Regular.woff")
      format("woff"),
    url("./assets/fonts/SunLifeSans-Regular/SunLifeSans-Regular.otf")
      format("otf"),
    url("./assets/fonts/SunLifeSans-Regular/SunLifeSans-Regular.ttf")
      format("ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Sun Life Sans";
  src: url("./assets/fonts/SunLifeSans-RegularItalic/SunLifeSans-RegularItalic.eot")
      format("embedded-opentype"),
    url("./assets/fonts/SunLifeSans-RegularItalic/SunLifeSans-RegularItalic.woff")
      format("woff"),
    url("./assets/fonts/SunLifeSans-RegularItalic/SunLifeSans-RegularItalic.otf")
      format("otf"),
    url("./assets/fonts/SunLifeSans-RegularItalic/SunLifeSans-RegularItalic.ttf")
      format("truetype"),
    url("./assets/fonts/SunLifeSans-RegularItalic/SunLifeSans-RegularItalic.svg")
      format("svg");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Sun Life Sans";
  src: url("./assets/fonts/SunLifeSans-Medium/SunLifeSans-Medium.eot")
      format("embedded-opentype"),
    url("./assets/fonts/SunLifeSans-Medium/SunLifeSans-Medium.woff")
      format("woff"),
    url("./assets/fonts/SunLifeSans-Medium/SunLifeSans-Medium.otf")
      format("otf"),
    url("./assets/fonts/SunLifeSans-Medium/SunLifeSans-Medium.ttf")
      format("truetype"),
    url("./assets/fonts/SunLifeSans-Medium/SunLifeSans-Medium.svg")
      format("svg");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Sun Life Sans";
  src: url("./assets/fonts/SunLifeSans-MediumItalic/SunLifeSans-MediumItalic.eot")
      format("embedded-opentype"),
    url("./assets/fonts/SunLifeSans-MediumItalic/SunLifeSans-MediumItalic.woff")
      format("woff"),
    url("./assets/fonts/SunLifeSans-MediumItalic/SunLifeSans-MediumItalic.otf")
      format("otf"),
    url("./assets/fonts/SunLifeSans-MediumItalic/SunLifeSans-MediumItalic.ttf")
      format("truetype"),
    url("./assets/fonts/SunLifeSans-MediumItalic/SunLifeSans-MediumItalic.svg")
      format("svg");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Sun Life Sans";
  src: url("./assets/fonts/SunLifeSans-Bold/SunLifeSans-Bold.eot")
      format("embedded-opentype"),
    url("./assets/fonts/SunLifeSans-Bold/SunLifeSans-Bold.woff") format("woff"),
    url("./assets/fonts/SunLifeSans-Bold/SunLifeSans-Bold.otf") format("otf"),
    url("./assets/fonts/SunLifeSans-Bold/SunLifeSans-Bold.ttf")
      format("truetype"),
    url("./assets/fonts/SunLifeSans-Bold/SunLifeSans-Bold.svg") format("svg");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Sun Life Sans";
  src: url("./assets/fonts/SunLifeSans-BoldItalic/SunLifeSans-BoldItalic.eot")
      format("embedded-opentype"),
    url("./assets/fonts/SunLifeSans-BoldItalic/SunLifeSans-BoldItalic.woff")
      format("woff"),
    url("./assets/fonts/SunLifeSans-BoldItalic/SunLifeSans-BoldItalic.otf")
      format("truetype"),
    url("./assets/fonts/SunLifeSans-BoldItalic/SunLifeSans-BoldItalic.ttf")
      format("truetype"),
    url("./assets/fonts/SunLifeSans-BoldItalic/SunLifeSans-BoldItalic.svg")
      format("svg");
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

html,
body {
  font-size: calc(100vw / 11.94);
  color: #424242;
  touch-action: manipulation;
}

body,
body * > *:not(script):not(style) {
  box-sizing: border-box;
  font-family: "Sun Life Sans", sans-serif;
  margin: 0;
  padding: 0;
}

#root svg {
  overflow: initial;
}

.ant-input {
  font-size: 0.16rem !important;
}

.down-enter-active,
.down-exit-active {
  opacity: 1;
  transform: translateY(0);
}

.down-enter {
  transform: translateY(-1.8rem);
  opacity: 0;
}

.down-exit {
  transform: translateY(-1.8rem);
  opacity: 0;
}

.slow-down-active,
.slow-down-active {
  opacity: 1;
  transform: translateY(0);
}

.slow-down-enter {
  transform: translateY(-0.4rem);
  opacity: 0;
}

.slow-down-exit {
  transform: translateY(-0.4rem);
  opacity: 0;
}

.short-up-enter-active,
.short-up-exit-active {
  opacity: 1;
  transform: translateY(0);
}

.short-up-enter {
  transform: translateY(0.2rem);
  opacity: 0;
}

.short-up-exit {
  transform: translateY(0.1rem);
  opacity: 0;
}

.side-in-enter-done {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.select-option-selected {
  background: #f4f7f6 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #f4f7f6 !important;
  font-weight: 400 !important;
}

.ant-select-dropdown {
  padding: 0;
}

.ant-select-item-empty {
  padding: 0;
  display: none;
}

.ant-modal-wrap,
.ant-image-preview-wrap {
  position: absolute;
}

.fade-enter-active,
.fade-exit-active {
  opacity: 1;
}

.fade-enter,
.fade-exit {
  opacity: 0;
}
